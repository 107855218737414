jQuery(function() {
	initTabs();
    initSlick();
    initAnchors();
    initShowOnScroll();
    initLottie();
    initFancybox();
});

function initFancybox() {
    $().fancybox({
        parentEl: 'body',
        selector : '[data-fancybox]',
        margin: [50, 0],
        toolbar  : true,
        smallBtn : false,
        buttons : false,
        infobar : false,
        hash: false,
        loop : true,
        animationEffect: "zoom-in-out",
        transitionEffect: "slide",
        transitionDuration: 700,
        buttons: ["close"],
        touch : {
            vertical : false, 
            momentum : true   
        }
    });
}

function initLottie() {
    var paramsDrupal = {
        container: document.getElementById('drupal-anim'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'data/Drupal_Character.json'
    };
    var animDrupal = lottie.loadAnimation(paramsDrupal);

    var paramsWP = {
        container: document.getElementById('wp-anim'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'data/Wordpress_Character.json'
    };
    var animWP = lottie.loadAnimation(paramsWP);

    var paramsTabDrupal = {
        container: document.getElementById('tab-drupal-anim'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'data/Drupal_Construction_Site.json'
    };
    var animTabDrupal = lottie.loadAnimation(paramsTabDrupal);

    var paramsTabWP = {
        container: document.getElementById('tab-wp-anim'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'data/Wordpress_Construction_Site.json'
    };
    var animTabWP = lottie.loadAnimation(paramsTabWP);

    var paramsTools = {
        container: document.getElementById('tools'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'data/Tools.json'
    };
    var animTools = lottie.loadAnimation(paramsTools);
}

function initShowOnScroll() {
    var fixEl = jQuery('.cms-top'),
        target = jQuery('.cms-intro');

    jQuery(window).on('scroll', function() {
        var scroll = jQuery(window).scrollTop();

        if (scroll >= target.offset().top - 30) {
            fixEl.addClass("show");
        } else {
            fixEl.removeClass("show");
        }
    });

}

function initAnchors() {
    jQuery('.anchor-btn').click(function(e) {
        var target = jQuery(this.hash);
        target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            e.preventDefault();
            jQuery('html,body').animate({
              scrollTop: target.offset().top - 30
            }, 700);
        }
    });
}

function initSlick() {
    jQuery('.js-cms-slider').slick({
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

// content tabs init
function initTabs() {
	jQuery('.js-tabset').tabset({
		tabLinks: 'a',
		defaultTab: true
	});
}

